import React, { useEffect, useState, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { marked } from 'marked';

import { Heading4 } from '@latitude/heading';
import Faq from '@/components/Faq/Faq';
import { Box } from '@/components/Box/Box';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import HowToApply from '@latitude/how-to-apply';
import { List, ListItem } from '@latitude/list';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/medical-loan.json';
import PLData from '@/data/pages/personal-loan.json';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import {
  redirectUrl,
  resetToDefault
} from '@/components/EstimateRateWidget/utils';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import CalcData from '../data/pages/loans/loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import { FeaturesSlider } from '@latitude/features-slider';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import { Link } from '@latitude/link/Link';
import ArticleTile from '@/templates/life-done-better/ArticleTile';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '@/utils/constants';
import { PLEligibilityCriteria } from '@/components/PersonalLoanPageContent/PersonalLoanPageContent';
import Modal from '@/components/Modal/Modal';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import footerData from '@/data/json/footer.json';
import MedicalList from './loans/_medical-loan-list.js';
import heroImage from '../images/hero/medical-loan.webp';
import SvgInline from '@latitude/svg-inline';
import Button from '../components/Button/Button';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

const MedicalLoanPage = ({ data, location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'medical-dental'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  return (
    <Layout
      location={location}
      metaDesc={PageData.metaDesc}
      title={PageData.title}
      canonical={location.href}
      customFooter={footerData}
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={'Medical Loans'}
              subTitle={
                <>
                  You look after yourself. We’ll look after the funding. Apply
                  for a Latitude Medical Loan online in just 7 minutes
                  <span className="conditional-text">
                    {' '}
                    and get a response to your application in 60 seconds.
                  </span>
                </>
              }
              pageImage={heroImage}
              pageImageAlt="loan for medical"
            >
              <div className="text-white row">
                <div className="text-left col-6 col-md-5">
                  <HomeButton
                    href={applyUrl}
                    trackId="medical-loans-button"
                    trackEventData={{
                      label: 'Get Rate Estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    Get Rate Estimate
                  </HomeButton>
                </div>
                <div className="p-0 text-center col-1">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="pt-1 text-left col-5 col-md-6 padding-right HeroContent__insettext">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
            </PageHeaderSection>
          )}

          <StickyNavContainer>
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </StickyNavContainer>
          <MedicalList />
          <EstimateWidget />
          <LoanRates
            isBranded
            rateBoxType="variableSym"
            rateBoxType2="fixedSym"
            fees={PLData.content.aboutTheLoan}
            determineRateLink
          />
          <FeesAndCharges data={PLData.content.feesAndCharges} />
          <FeatureTiles
            id="calculators-and-tools"
            heading={CalcData.content.calculatorsTitle}
            tiles={CalcData.content.calculators}
          />
          <FeaturesSliderContainer>
            <FeaturesSlider
              key={
                (state?.featureSliderData?.[0] || featureSliderData[0]).heading
              }
              id="why-us"
              data={
                (state?.featureSliderData?.[0] || featureSliderData[0])
                  .featureCards
              }
              className="pt-4 why-choose pt-md-5"
              heading={
                (state?.featureSliderData?.[0] || featureSliderData[0]).heading
              }
              subheading={
                (state?.featureSliderData?.[0] || featureSliderData[0])
                  .description
              }
            />
          </FeaturesSliderContainer>
          <HowToApplySection />
          <RecommendedReads data={data} />
          <FaqSection />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
};

// Variables ///////////////////////////////////////////////////////////////////
const healthCategories = [
  'Audiology services',
  'Bariatric surgery',
  'Cosmetic surgery',
  'Dental/Orthodontics',
  'ENT surgery',
  'Hair restoration surgery',
  'Laser hair treatment',
  'Laser eye surgery',
  'Obstetrics',
  'Orthopaedics',
  'Sleep apnoea treatment',
  'Veterinary care for your pet'
];

// Functions ///////////////////////////////////////////////////////////////////

// allows the 'get started' button to open in the same window
const applyFunc = `javascript:
    if( typeof window !== 'undefined' ){
      window.location = '${redirectUrl()}'
    };
  `;

// Hero ////////////////////////////////////////////////////////////////////////
const StyledHeroBranded = styled(HeroBranded)`
  background: ${COLOR.GREY6};
  @media (max-width: ${BREAKPOINT.MD}) {
    .heroTextSpacer {
      display: none;
    }
  }
  .HeroTextSpacer {
    display: block;
    height: 2em;
  }
`;

// StickyNav ///////////////////////////////////////////////////////////////////
const StickyNavContainer = styled.div`
  display: none;
  position: relative;
  z-index: 10;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }
`;

// Estimate rate widget ////////////////////////////////////////////////////////
const EstimateWidget = () => {
  resetToDefault(false, 'medical-dental'); // reset session storage values
  return <EstimateRateWidgetSection purpose="medical-dental" />;
};

// Features Slider /////////////////////////////////////////////////////////////
const FeaturesSliderContainer = styled.div`
  h2 {
    @media (min-width: ${BREAKPOINT.SM}) {
      font-size: 32px !important;
      line-height: 36px !important;
    }
  }
  && {
    background-color: #f8f8f8;
    padding-top: 40px !important;
    padding-bottom: 40px;

    .card-icon__image svg {
      fill: inherit !important;
      stroke: inherit !important;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px !important;
      padding-bottom: 49px;
    }
  }
`;

// How to apply ////////////////////////////////////////////////////////////////
const HowToApplySection = () => {
  const [eligibilityModal, setEligibilityModal] = useState(false);
  return (
    <>
      <HowToApply
        id="how-to-apply"
        box1={<HowToApplyList variant="sym" />}
        box2={
          <PLEligibilityCriteria
            customEligibilityCriteria={[
              ...PLData.content.eligibilityCriteria,
              <>
                Be borrowing for one of these{' '}
                <Link
                  href="javascript:;"
                  onClick={() => {
                    setEligibilityModal(true);
                  }}
                >
                  health categories
                </Link>
              </>
            ]}
          />
        }
      />
      <Modal
        css="width:100%"
        isOpen={eligibilityModal}
        onRequestClose={() => setEligibilityModal(false)}
        title="Health categories"
        customContent={
          <List>
            {PageData.healthCategories.map(item => (
              <ListItem key={`modal-${item}`}>{item}</ListItem>
            ))}
          </List>
        }
      />
    </>
  );
};

// Recommended reads ///////////////////////////////////////////////////////////
const RecommendedReads = props => (
  <Box.Section
    backgroundColor={COLOR.GREY5}
    paddingTop={MARGIN.M32}
    paddingBottom={MARGIN.M32}
  >
    <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
      Recommended reads
    </Heading4>
    <CardGroup>
      {props.data.allMarkdownRemark.edges.map(item => (
        <CardGroupItem key={item.node.frontmatter.path}>
          <ArticleTile isBranded {...item.node.frontmatter} />
        </CardGroupItem>
      ))}
    </CardGroup>
  </Box.Section>
);

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "Let's keep healthy over Christmas! Wait, what?"
              "Destination healthy: The Health Benefits of Travel"
              "How to Fund Your Dream Vacation"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

// Faqs ////////////////////////////////////////////////////////////////////////
// TODO
const FaqSection = () => {
  // use faq data from Personal loan page
  const faqData = PageData.faq;

  // modify first faq answer to be medical loan specific
  faqData[0].qa[0].a = customAnswer();

  function customAnswer() {
    let string = `You can use your Latitude Medical Loan for medical procedures if you're borrowing for one of the below health categories:<br />
      <br />
      <ul>
    `;
    PageData.healthCategories.map(item => {
      string += `<li>${item}</li>`;
    });
    string += `</ul>
      <br />
      If you have something in mind, or want more information on Personal Loans for medical and surgical procedures, give us a call <a href="tel:132826">13 28 26</a> to discuss a solution that suits you.
    `;
    return string;
  }

  return <Faq data={faqData} />;
};

export default MedicalLoanPage;
